import * as React from 'react'

import { Locale } from '../../utils/enums'

import IndexPage from './index'

const IndexPageDe = (): JSX.Element => {
    return <IndexPage locale={Locale.de_DE} />
}

export default IndexPageDe
